<template>
  <v-row no-gutters align="center" :justify="justify ? justify : 'center'">
    <span
      style="
        color: #828282;
        font-family: 'MacPaw Fixel Display';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: line-through;
      "
      :style="font_size ? `font-size: ${font_size}` : ''"
      >{{ old_price }} грн</span
    >
    <div
      style="
        border-radius: 10px;
        background: #d9250d;
        padding: 2px 4px;
        display: grid;
        place-items: center;
        color: #eee;
        font-family: 'MacPaw Fixel Display';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: max-content;
        margin-left: 4px;
      "
      :style="font_size ? `font-size: ${font_size}` : ''"
    >
      -{{ discount.toFixed(2) }}%
    </div>
  </v-row>
</template>

<script>
export default {
  props: {
    old_price: {
      require: true,
    },
    discount: {
      require: true,
    },
    font_size: {
      require: false,
    },
    justify: {
      require: false,
    },
  },
};
</script>

<style>
</style>