<template>
  <div class="ticketSearchCard">
    <v-row no-gutters align="center">
      <v-col class="px-0 py-0" cols="4" xl="3" lg="3" md="2" sm="3">
        <v-row no-gutters align="center">
          {{
            new Date(
              addMinutesToDate(
                trip.departed_at,
                trip?.route?.cities?.find(
                  (city) => city.station.city_uuid == trip.departure.id
                )?.time_from_start
              )
            ).toLocaleTimeString("uk-UA", {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}
          <span
            v-if="!$vuetify.breakpoint.smAndDown"
            class="ticketSearchCardText"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'margin-top: 4px;color:#989898'
                : 'margin: 0px 8px'
            "
          >
            {{
              new Date(
                addMinutesToDate(
                  trip.departed_at,
                  trip?.route?.cities?.find(
                    (city) => city.station.city_uuid == trip.departure.id
                  )?.time_from_start
                )
              ).toLocaleDateString("uk-UA", {
                day: "2-digit",
                month: "long",
                timeZone: "Europe/Kiev",
              })
            }}</span
          >
          <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
            <template v-slot:activator="{ on, attrs }">
              <div class="helpIcon" v-bind="attrs" v-on="on"></div>
            </template>
            <span>{{ "time_zone_warning" | localize }}</span>
          </v-tooltip>
        </v-row>
        <span
          v-if="$vuetify.breakpoint.smAndDown"
          class="ticketSearchCardText"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'margin-top: 4px;color:#989898'
              : 'margin: 0px 8px'
          "
          >{{
            new Date(
              addMinutesToDate(
                trip.departed_at,
                trip?.route?.cities?.find(
                  (city) => city.station.city_uuid == trip.departure.id
                )?.time_from_start
              )
            ).toLocaleDateString("uk-UA", {
              day: "2-digit",
              month: "long",
              timeZone: "Europe/Kiev",
            })
          }}</span
        >
        <p class="ticketSearchCardTitle">
          {{ trip?.departure_stations?.translations?.name }}
        </p>
        <p class="ticketSearchCardText">
          {{ trip?.departure_stations?.translations?.address }}
        </p>
      </v-col>
      <v-col class="px-0 py-0" cols="4" xl="3" lg="3" md="2" sm="4">
        <v-row no-gutters align="center">
          <v-col cols="1" xl="2" lg="2" md="2" sm="1">
            <div
              style="
                width: 100%;
                max-width: 46px;
                height: 0.5px;
                background-color: #989898;
              "
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'width: 1px'
                  : 'min-width: 17.5px;'
              "
            ></div>
          </v-col>
          <v-col cols="9" xl="7" lg="7" md="7" sm="9">
            <v-row no-gutters align="center" justify="center">
              <span
                class="ticketSearchCardText"
                style="font-size: 14px"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? 'font-size: 10px;color:#989898'
                    : ''
                "
                >{{
                  setDifferentTime(
                    addMinutesToDate(
                      trip.departed_at,
                      trip?.route?.cities?.find(
                        (city) => city.station.city_uuid == trip.departure.id
                      )?.time_from_start
                    ),
                    addMinutesToDate(
                      trip.departed_at,
                      trip?.route?.cities?.find(
                        (city) => city.station.city_uuid == trip.arrival.id
                      )?.time_from_start
                    )
                  )
                }}</span
              >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="helpIcon"
                    v-bind="attrs"
                    v-on="on"
                    style="margin-left: 4px"
                  ></div>
                </template>
                <span>{{ "time_zone_warning" | localize }}</span>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-col cols="1" xl="2" lg="2" md="2" sm="1">
            <div
              style="
                width: 100%;
                max-width: 46px;
                height: 0.5px;
                background-color: #989898;
              "
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'width: 1px'
                  : 'min-width: 17.5px;'
              "
            ></div
          ></v-col>
        </v-row>
      </v-col>
      <v-col class="px-0 py-0" cols="4" xl="3" lg="3" md="3" sm="3">
        <v-row no-gutters align="center">
          {{
            new Date(
              addMinutesToDate(
                trip.departed_at,
                trip?.route?.cities?.find(
                  (city) => city.station.city_uuid == trip.arrival.id
                )?.time_from_start
              )
            ).toLocaleTimeString("uk-UA", {
              hour: "2-digit",
              minute: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}
          <span
            class="ticketSearchCardText"
            style="margin: 0px 8px"
            v-if="!$vuetify.breakpoint.smAndDown"
            >{{
              new Date(
                addMinutesToDate(
                  trip.departed_at,
                  trip?.route?.cities?.find(
                    (city) => city.station.city_uuid == trip.arrival.id
                  )?.time_from_start
                )
              ).toLocaleDateString("uk-UA", {
                day: "2-digit",
                month: "long",
                timeZone: "Europe/Kiev",
              })
            }}</span
          >
          <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
            <template v-slot:activator="{ on, attrs }">
              <div class="helpIcon" v-bind="attrs" v-on="on"></div>
            </template>
            <span>{{ "time_zone_warning" | localize }}</span>
          </v-tooltip>
        </v-row>
        <span
          v-if="$vuetify.breakpoint.smAndDown"
          class="ticketSearchCardText"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'margin-top: 4px;color:#989898'
              : 'margin: 0px 8px'
          "
          >{{
            new Date(
              addMinutesToDate(
                trip.departed_at,
                trip?.route?.cities?.find(
                  (city) => city.station.city_uuid == trip.arrival.id
                )?.time_from_start
              )
            ).toLocaleDateString("uk-UA", {
              day: "2-digit",
              month: "long",
              timeZone: "Europe/Kiev",
            })
          }}</span
        >
        <p class="ticketSearchCardTitle">
          {{ trip?.arrival_stations?.translations?.name }}
        </p>
        <p class="ticketSearchCardText">
          {{ trip?.arrival_stations?.translations?.address }}
        </p>
      </v-col>
      <v-col style="text-align: center" v-if="!$vuetify.breakpoint.smAndDown">
        <old-price-label
          v-if="trip.discount_price !== null"
          :discount="trip?.price?.discount_percent"
          :old_price="trip.price?.price"
          :font_size="'10px'"
        />
        {{
          trip?.price?.discount_price !== "0"
            ? trip?.price?.discount_price
            : trip.price?.price
        }}
        грн
        <v-row no-gutters justify="center">
          <submit-button
            style="width: 180px; margin-top: 12px"
            @click="bookingTrip"
            >{{ "buy_btn" | localize }}</submit-button
          >
        </v-row>
        <v-row
          no-gutters
          align="center"
          justify="center"
          style="margin-top: 12px"
        >
          <img
            src="@/assets/img/iconsSvg/bonusIcon.svg"
            alt="bonus icon"
            style="margin-right: 8px"
          />
          <span class="ticketSearchCardText"
            >+
            {{
              trip?.price?.discount_price !== "0"
                ? (trip?.price?.discount_price * bonus_amount).toFixed(2)
                : (trip.price?.price * bonus_amount).toFixed(2)
            }}
            {{ "quantity_bonus_label" | localize }}</span
          >
        </v-row>
      </v-col>
      <v-row no-gutters style="margin-top: 20px" align="center" v-else>
        <v-col cols="6">
          <old-price-label
            v-if="trip.discount_price !== null"
            :discount="trip?.price?.discount_percent"
            :old_price="trip.price?.price"
            :font_size="'10px'"
            justify="start"
          />
          {{
            trip?.price?.discount_price !== "0"
              ? trip?.price?.discount_price
              : trip.price?.price
          }}
          грн
          <v-row
            no-gutters
            align="center"
            style="margin-top: 8px"
            v-if="$vuetify.breakpoint.smAndDown"
          >
            <img
              src="@/assets/img/iconsSvg/bonusIcon.svg"
              alt="bonus icon"
              style="margin-right: 8px"
            />
            <span class="ticketSearchCardText"
              >+
              {{
                trip?.price?.discount_price !== "0"
                  ? (trip?.price?.discount_price * bonus_amount).toFixed(2)
                  : (trip.price?.price * bonus_amount).toFixed(2)
              }}
              {{ "quantity_bonus_label" | localize }}</span
            >
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row no-gutters justify="center">
            <submit-button style="width: 100%" @click="bookingTrip">{{
              "buy_btn" | localize
            }}</submit-button>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
    <button
      class="detailBtn"
      style="margin-top: 20px"
      :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : ''"
      @click="isDetail = !isDetail"
    >
      {{ "detail_btn" | localize }}
      <v-icon style="margin-left: 10px">{{
        isDetail ? "mdi-chevron-up" : "mdi-chevron-down"
      }}</v-icon>
    </button>
    <v-expand-transition>
      <ticket-search-card-detail v-if="isDetail" :trip="trip" />
    </v-expand-transition>
  </div>
</template>

<script>
import submitButton from "../Buttons/submitButton.vue";
import TicketSearchCardDetail from "./ticketSearchCardDetail.vue";
import diffrentTimeMixin from "../../../mixins/diffrentTimeMixin";
import OldPriceLabel from "../oldPriceLabel.vue";
export default {
  components: { submitButton, TicketSearchCardDetail, OldPriceLabel },
  mixins: [diffrentTimeMixin],
  data: () => ({
    isDetail: false,
    bonus_amount: parseFloat(localStorage.getItem("bonus_amount")) / 100,
  }),
  props: {
    trip: {
      require: true,
    },
  },
  methods: {
    async bookingTrip() {
      let recent_trip = this.$route.query;
      let trip = {
        departureCity: this.trip?.departure?.id,
        arrivalCity: this.trip.arrival?.id,
        date: recent_trip.departureDate,
        qty: recent_trip.qty,
        trip_id: this.trip.id,
      };
      console.log("TRIP", trip);
      sessionStorage.setItem("recent_trip_search", JSON.stringify(trip));
      this.$router.push(`/passanger`);
      localStorage.setItem("startDate", new Date());
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 1023px) {
  .ticketSearchCard {
    padding: 16px;
    border-radius: 10px;
    background: #fcfcfc;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }
  .ticketSearchCardTitle {
    font-size: 16px;
    font-weight: 600;
    margin: 4px 0px;
  }
  .ticketSearchCardText {
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0px;
  }
  .ticketSearchCardSubtitle {
    font-size: 14px;
    font-weight: 400;
    color: #989898;
  }
  .detailBtn {
    border-radius: 10px;
    background: rgba(20, 79, 169, 0.1);
    padding: 4px 8px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
@media screen and (min-width: 1024px) {
  .ticketSearchCard {
    padding: 16px;
    border-radius: 10px;
    background: #fcfcfc;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }
  .ticketSearchCardTitle {
    font-size: 16px;
    font-weight: 600;
    margin: 4px 0px;
  }
  .ticketSearchCardText {
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0px;
  }
  .ticketSearchCardSubtitle {
    font-size: 14px;
    font-weight: 400;
    color: #989898;
  }
  .detailBtn {
    border-radius: 10px;
    background: rgba(20, 79, 169, 0.1);
    padding: 4px 8px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>